import { OrderRfqOrigin, OrderStatus } from '../apiClient/generated';
import {
    EditableOrder,
    Order,
    OrderToDisplayInOrdersList,
    Product,
    RFQ,
    SeasonalPeriod,
} from '../types/order.types';
import { OrderTypes } from '../context/OrderTypes';
import { db } from './db';
import { createNewLineItemFromProduct } from './utils/products';
import { getLatestRfq } from './utils/getLatestRfq';
import { dataFlowEventHub } from '../events/dataFlowEvents';
import { v4, validate as uuidValidate } from 'uuid';
import { getVesselMetadata } from '../hooks/useVesselMetadata';
import { adjustEditableOrderToDisplayInOrdersList } from '../components/utils/adjustEditableOrderToDisplayInOrdersList';
import { adjustOrderToDisplayInOrdersList } from '../components/utils/adjustOrderToDisplayInOrdersList';
import { adjustOrderForReviewToEditableOrder } from '../components/utils/adjustOrderForReviewToEditableOrder';
import moment from 'moment';

export const resetEditableOrderById = async (orderId: string | number) => {
    if (typeof orderId === 'string' && uuidValidate(orderId)) {
        const editableOrderCollection = await db.editableOrders
            .where('localOrderId')
            .equalsIgnoreCase(orderId);

        const editableOrder = await editableOrderCollection.first();

        await editableOrderCollection.delete();

        dataFlowEventHub.emit('editableOrderChanged', {
            localOrderId: orderId,
            orderId: editableOrder?.orderId ?? 0,
        });

        if (editableOrder?.orderId) {
            dataFlowEventHub.emit('orderChanged', editableOrder.orderId);
        }
    } else {
        const editableOrderCollection = await db.editableOrders
            .where('orderId')
            .equals(orderId);

        const editableOrder = await editableOrderCollection.first();

        await editableOrderCollection.delete();

        dataFlowEventHub.emit('orderChanged', Number(orderId));
        dataFlowEventHub.emit('editableOrderChanged', {
            localOrderId: editableOrder?.localOrderId ?? '',
        });
    }
};

export const updateLineItemQuantity = async (
    order: EditableOrder,
    product: Product,
    newAmount: number,
    shouldRemoveZeroQuantityProduct = true,
) => {
    if (!product || isNaN(Number(newAmount))) {
        return;
    }

    const updatedOrder = JSON.parse(JSON.stringify(order)) as EditableOrder;
    let lineItems = updatedOrder.rfq.lineItems;

    // Find existing item by itemNumber
    const existingItemIndex = lineItems.findIndex(
        (item) => item.itemNumber === product.itemNumber
    );

    if (newAmount > 0) {
        if (existingItemIndex === -1) {
            // Add new line item if not found
            const newLineItem = createNewLineItemFromProduct(product, Number(newAmount));
            lineItems.push(newLineItem);
        } else {
            // Update existing item's quantity and modified date
            lineItems[existingItemIndex].quantity = newAmount;
            lineItems[existingItemIndex].modifiedAt = moment().toISOString(); // Ensure timestamp is updated
        }
    } else {
        // If quantity is zero, update the quantity and modified date
        if (existingItemIndex !== -1) {
            lineItems[existingItemIndex].quantity = 0;
            lineItems[existingItemIndex].modifiedAt = moment().toISOString(); // Ensure timestamp is updated
        }
    }

    // Save changes to IndexedDB
    await db.editableOrders.put(updatedOrder).then(() => {
    }).catch((error) => {
        console.error('Error updating order in database:', error);
    });

    dataFlowEventHub.emit('editableOrderChanged', {
        localOrderId: order.localOrderId,
        updatedOrder,
        orderId: order.orderId,
    });
};

export const putCommentToLineItemInEditableOrder = async (
    editableOrder: EditableOrder | undefined,
    itemNumber: string,
    comment: string,
) => {
    if (!editableOrder?.rfq) {
        return;
    }

    const updatedOrder = { ...editableOrder };
    const lineItemToUpdate = updatedOrder.rfq.lineItems.find(
        (item) => item.itemNumber === itemNumber,
    );

    if (lineItemToUpdate) {
        lineItemToUpdate.comment = comment;
        lineItemToUpdate.modifiedAt = moment().toISOString(); // Update the modified date
    }

    await db.editableOrders.put(updatedOrder);
    dataFlowEventHub.emit('editableOrderChanged', {
        localOrderId: updatedOrder.localOrderId,
        updatedOrder: updatedOrder,
        orderId: updatedOrder.orderId,
    });
};

export const putCommentToWholeEditableOrder = async (
    editableOrder: EditableOrder,
    comment: string | undefined,
) => {
    if (!editableOrder?.rfq) {
        return;
    }
    const updatedOrder = { ...editableOrder };
    updatedOrder.rfq.comment = comment;

    await db.editableOrders.put(updatedOrder);
    dataFlowEventHub.emit('editableOrderChanged', {
        localOrderId: updatedOrder.localOrderId,
        updatedOrder: updatedOrder,
        orderId: updatedOrder.orderId,
    });
};

export const recalculateAmountsInEditableOrderByRatio = async (
    type: OrderTypes,
    ratio: number,
    emitDataFlowEvent: boolean,
) => {
    const editableOrder = await getEditableOrderByType(type);
    if (!editableOrder) return;

    const lineItems = [...(editableOrder?.rfq?.lineItems || [])];

    if (lineItems?.length) {
        for (const lineItem of lineItems) {
            lineItem.quantity = lineItem.quantity * ratio;
            lineItem.modifiedAt = moment().toISOString(); // Ensure timestamp is updated
        }

        await updateEditableOrder(
            editableOrder,
            {
                ...editableOrder,
                rfq: { ...editableOrder.rfq, lineItems },
            },
            emitDataFlowEvent,
        );
    }
};

export const getEditableOrderByType = (
    type: OrderTypes,
): Promise<EditableOrder | undefined> => {
    return db.editableOrders.where('type').equalsIgnoreCase(type).first();
};

export const getEditableOrderById = async (
    orderIdOrLocalOrderId: string | number,
) => {
    if (uuidValidate(orderIdOrLocalOrderId.toString())) {
        return db.editableOrders
            .where('localOrderId')
            .equalsIgnoreCase(orderIdOrLocalOrderId.toString())
            .first();
    } else {
        return db.editableOrders
            .where('orderId')
            .equals(Number(orderIdOrLocalOrderId))
            .first();
    }
};

export const getAndUpdateEditableOrders = async (): Promise<
    EditableOrder[] | undefined
> => {
    const orders = await db.orders.toArray();
    const editableOrders = await db.editableOrders.toArray();

    const awaitingActionOrders = orders.filter(
        (order) => order.status === OrderStatus.OrderForReview,
    );

    for (const awaitingOrder of awaitingActionOrders) {
        const hasEditableVersion = editableOrders.find(
            (editableOrder) => editableOrder.orderId === awaitingOrder.orderId,
        );

        if (!hasEditableVersion) {
            await db.editableOrders.put(
                adjustOrderForReviewToEditableOrder(awaitingOrder),
            );
        }
    }

    return db.editableOrders.toArray();
};

export interface LastCommentFromRfqFlow {
    comment: string | undefined | null;
    origin: OrderRfqOrigin | undefined;
    createdAt: Date | undefined;
}

export const adjustAwaitingActionOrderToDisplayInOrdersList = (
    awaitingActionOrder: Order,
    awaitingActionEditableOrder: EditableOrder,
): OrderToDisplayInOrdersList => {
    const isSame =
        awaitingActionOrder?.orderId === awaitingActionEditableOrder?.orderId;

    if (isSame) {
        return {
            ...adjustEditableOrderToDisplayInOrdersList(
                awaitingActionEditableOrder,
            ),
            wbPono: getLatestRfq(awaitingActionOrder)?.wbPono,
            orderId: awaitingActionOrder?.orderId,
        };
    } else {
        return {
            ...adjustOrderToDisplayInOrdersList(awaitingActionOrder),
            isSending: awaitingActionEditableOrder?.isSending,
        };
    }
};

export const updateEditableOrder = async (
    editableOrder: EditableOrder,
    updated: Partial<EditableOrder>,
    emitDataFlowEvent = true,
) => {
    await db.editableOrders.update(editableOrder, updated);
    if (emitDataFlowEvent) {
        dataFlowEventHub.emit('editableOrderChanged', {
            localOrderId: editableOrder.localOrderId,
            updatedOrder: editableOrder,
            orderId: editableOrder.orderId,
        });
    }
};

const createInitialEditableOrder = (
    orderType: OrderTypes,
    currency?: string,
    periods: SeasonalPeriod[] = [],
) => {
    const editableOrder: EditableOrder = {
        localOrderId: v4(),
        type: orderType,
        status: OrderStatus.Blank,
        rfq: JSON.parse(
            JSON.stringify(
                db.createInitialRfq(currency ?? db.defaults.currency),
            ),
        ),
        periods,
    };

    return editableOrder;
};

export const createEditableOrderOfTypeWithRfqDetails = async (
    type: OrderTypes,
    initialRfqDetails?: Partial<RFQ>,
) => {
    const { vesselCurrency } = (await getVesselMetadata()) ?? {};
    const initialEditableOrder = createInitialEditableOrder(
        type,
        vesselCurrency ?? undefined,
    );

    const newEditableOrder = {
        ...initialEditableOrder,
        rfq: {
            ...initialEditableOrder.rfq,
            ...initialRfqDetails,
        },
    };

    await db.editableOrders.add(newEditableOrder);

    dataFlowEventHub.emit('editableOrderChanged', {
        localOrderId: newEditableOrder.localOrderId,
        updatedOrder: newEditableOrder,
        orderId: newEditableOrder.orderId,
    });

    return newEditableOrder.localOrderId;
};

export const updateEditableOrderPeriods = async (
    orderToDisplay: { localOrderId: string },
    matchingPeriods: SeasonalPeriod[],
) => {
    if (orderToDisplay.localOrderId && matchingPeriods) {
        const editableOrder = await db.editableOrders
            .where('localOrderId')
            .equals(orderToDisplay.localOrderId)
            .first();

        if (editableOrder) {
            editableOrder.periods = matchingPeriods.map((period) => ({
                ...period,
                fromDate: moment(new Date(period.fromDate)).format(
                    'DD-MM-YYYY',
                ),
                toDate: moment(new Date(period.toDate)).format('DD-MM-YYYY'),
            }));

            await db.editableOrders.put(editableOrder);
            dataFlowEventHub.emit('editableOrderChanged', {
                localOrderId: editableOrder.localOrderId,
                updatedOrder: editableOrder,
                orderId: editableOrder.orderId,
            });
        }
    }
};
