import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { CustomDatePickerHeader } from './CustomDatePickerHeader';
import { CustomDatePickerInput } from './CustomDatePickerInput';
import { v4 } from 'uuid';
import { Portal } from '@radix-ui/react-portal';
import { useRef, useState } from 'react';
import { enUS as en } from 'date-fns/locale';

interface CustomDatePickerProps {
    selectedDate?: Date | null;
    setSelectedDate: (deliveryDate: Date | null) => void;
    selectedEndDate?: Date | null;
    setSelectedEndDate?: (endDate: Date | null) => void;
    label?: string;
    strongLabel?: boolean;
    error?: string;
    name?: string;
    disabled?: boolean;
    futureDates?: boolean;
    pastDates?: boolean;
    maxDate?: Date;
    minDate?: Date;
    monthArrowsDisabled?: boolean;
    selectingDateRangeEnabled?: boolean;
    inlineCalendar?: boolean;
    openDate?: Date;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
    selectedDate,
    setSelectedDate,
    label,
    strongLabel,
    error,
    name,
    disabled,
    futureDates,
    pastDates,
    maxDate,
    monthArrowsDisabled,
    inlineCalendar,
    setSelectedEndDate,
    selectedEndDate,
    selectingDateRangeEnabled,
    minDate,
    openDate,
}) => {
    const [dayHovered, setDayHovered] = useState<Date | undefined>();

    const isSelectedEndDate = (date: Date) => {
        if (!selectedDate) return false;
        return moment(date).isSame(selectedEndDate, 'day');
    };

    const isRange = (date: Date) => {
        if (!selectedEndDate) return false;
        return moment(date).isBetween(selectedDate, selectedEndDate, 'day');
    };

    const isHoveredRange = (date: Date) => {
        if (!selectedDate || !dayHovered) return false;
        return moment(date).isBetween(selectedDate, dayHovered, 'day');
    };

    const getMaxDate = () => {
        if (maxDate) return maxDate;
        if (pastDates) return new Date();
    };

    const getMinDate = () => {
        if (futureDates) return new Date();
        return minDate || undefined;
    };

    const handleHover = (item: Date | undefined) => setDayHovered(item);

    const isValidDate = (date: any): boolean => {
        return date instanceof Date && !isNaN(date.getTime());
    };

    const onChangeHandler = (date: Array<Date | null> | Date | null) => {
        if (!date) return;

        if (date instanceof Date) {
            setSelectedDate(isValidDate(date) ? date : null);
        } else {
            const [startDate, endDate] = date;
            setSelectedDate(isValidDate(startDate) ? startDate : null);
            setSelectedEndDate?.(isValidDate(endDate) ? endDate : null);
        }
    };

    const pickerId = useRef(`react-datepicker-${v4()}`);

    return (
        <>
            <ReactDatePicker
                calendarClassName={inlineCalendar ? 'react-datepicker-inline' : ''}
                dayClassName={(date: Date) => {
                    if (isSelectedEndDate(date) && selectingDateRangeEnabled) {
                        return 'react-datepicker__day--selected';
                    } else if (isRange(date)) {
                        return 'react-datepicker__day--selected';
                    } else if (
                        isHoveredRange(date) &&
                        dayHovered &&
                        selectingDateRangeEnabled &&
                        !selectedEndDate
                    ) {
                        return 'react-datepicker__hovered-range';
                    } else {
                        return '';
                    }
                }}
                selected={isValidDate(selectedDate) ? selectedDate : undefined}
                onChange={(date) => onChangeHandler(date)}
                openToDate={openDate ?? minDate}
                startDate={isValidDate(selectedDate) ? selectedDate : undefined}
                endDate={isValidDate(selectedEndDate) ? selectedEndDate : undefined}
                selectsRange={selectingDateRangeEnabled}
                minDate={getMinDate()}
                maxDate={getMaxDate()}
                disabled={disabled}
                customInput={<CustomDatePickerInput label={label} strongLabel={strongLabel} error={error} />}
                renderCustomHeader={(params) =>
                    CustomDatePickerHeader({
                        ...params,
                        prevMonthButtonDisabled: monthArrowsDisabled ?? false,
                        nextMonthButtonDisabled: monthArrowsDisabled ?? false,
                    })
                }
                popperModifiers={[
                    {
                        name: 'preventOverflow',
                        options: {
                            rootBoundary: 'viewport',
                            tether: false,
                            altAxis: true,
                        },
                    },
                ]}
                name={name}
                inline={inlineCalendar}
                portalId={pickerId.current}
                onDayMouseEnter={(date) => handleHover(date)}
                onMonthMouseLeave={() => handleHover(undefined)}
                locale={en}
            />
            <Portal id={pickerId.current} />
        </>
    );
};

export default CustomDatePicker;
